/** @jsx jsx */
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews'
import { jsx } from 'theme-ui'
import { Layout, Section } from '../components'

const linkResolver = require('../utils/linkResolver')

const PreviewPage = () => (
  <Layout>
    <Section>
      <p
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          minHeight: '30vh',
        }}
      >
        Loading …
      </p>
    </Section>
  </Layout>
)

export default withPrismicPreviewResolver(PreviewPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
